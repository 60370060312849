import React from "react";
import { StaticQuery, graphql } from "gatsby";
import uniqid from "uniqid";
import { Location } from "@reach/router";
import Obfuscate from "react-obfuscate";

import { formatLineBreak } from "../../utils";

import Button from "../Button/Button";

import styles from "./Footer.module.scss";

function Footer() {
	return (
		<StaticQuery
			query={graphql`
				query {
					data: file(name: { eq: "contact" }) {
						id
						childMarkdownRemark {
							frontmatter {
								address
								phone
								email
							}
						}
					}
				}
			`}
			render={({ data }) => {
				const {
					childMarkdownRemark: {
						frontmatter: { address, phone, email },
					},
				} = data;

				return (
					<footer className={styles.footer__wrapper}>
						<Location>
							{({ location }) => {
								if (location.pathname !== "/contact" && !location.pathname.includes("/contact/")) {
									return (
										<section className={styles.cta__wrapper}>
											<p className={styles.cta__copy}>
												Let’s do something together? Get in touch for questions,
												<br /> budgets and partnerships at hello@secrethideout.sg or leave your
												message here!
											</p>

											<Button text="CHAT WITH US" to="/contact" />
										</section>
									);
								}

								return null;
							}}
						</Location>

						<section className={styles.address__wrapperMain}>
							<div className={styles.address__wrapperContent}>
								<div className={styles.address__wrapperCol}>
									<div className={styles.address__header}>Address</div>
									{formatLineBreak(address).map((line) => (
										<p key={uniqid()} className={styles.address__liner}>
											{line}
										</p>
									))}
								</div>
								<div className={styles.address__wrapperCol}>
									<div className={styles.address__header}>Contact</div>
									<div className={styles.address__liner}>
										<Obfuscate email={email} />
									</div>
									<div className={styles.address__liner}>
										<Obfuscate tel={phone} />
									</div>
								</div>
								{/* <div className={styles.address__wrapperCol}>
									<div className={styles.address__header}>Social</div>
									<div className={styles.address__liner}>
										<Obfuscate email={email} />
									</div>
									<div className={styles.address__liner}>
										<Obfuscate tel={phone} />
									</div>
								</div> */}
							</div>

							<section className={styles.rights}>Copyright © Secret Hideout Pte Ltd</section>
						</section>
					</footer>
				);
			}}
		/>
	);
}

export default Footer;
