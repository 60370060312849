/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */

import "./src/styles/index.scss";
import "./static/fonts/fonts.css";

export const onRouteUpdate = ({ location, prevLocation }) => {
	if (location && location.state)
		location.state.referrer = prevLocation ? prevLocation.pathname : null;
};

const PAGE_TRANSITION_DELAY = 500;
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
	if (location.action === "PUSH") {
		window.setTimeout(() => window.scrollTo(0, 0), PAGE_TRANSITION_DELAY);
	} else {
		const savedPosition = getSavedScrollPosition(location);
		window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), PAGE_TRANSITION_DELAY);
	}
	return false;
};
