import React, { PureComponent } from "react";
import MediaQuery from "react-responsive";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";

import SEO from "../../components/SEO/SEO";
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";

import styles from "./Main.module.scss";

const propTypes = {
	children: PropTypes.object.isRequired,
};

/**
 * !!! Ensure that shouldUpdateScroll in [gatsby-browser] is in sync
 * !!! with transition timing. Else page scroll will jump to top prematurely
 */
const pageVariants = {
	initial: {
		opacity: 0,
		y: -10,
	},
	in: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.75,
			ease: [0.25, 1, 0.5, 1],
		},
	},
	out: {
		opacity: 0,
		transition: {
			duration: 0.5,
			ease: [0.55, 0, 1, 0.45],
		},
	},
};

class Main extends PureComponent {
	render() {
		const {
			children,
			children: {
				props: { path },
			},
		} = this.props;

		return (
			<MediaQuery minWidth={900}>
				{(matches) => (
					<main className={styles.main__wrapper}>
						<SEO />
						<Nav isDesktop={matches} />
						<AnimatePresence exitBeforeEnter>
							<motion.section
								key={path}
								initial="initial"
								animate="in"
								exit="out"
								variants={pageVariants}
								className={`${styles.children__wrapper} ${
									matches ? "" : styles["children--isMobile"]
								}`}
							>
								{children}
							</motion.section>
						</AnimatePresence>
						<Footer />
					</main>
				)}
			</MediaQuery>
		);
	}
}

Main.propTypes = propTypes;
export default Main;
