const remark = require("remark");
const remarkHTML = require("remark-html");

/**
 * convert markdown-styled line breaks into its HTML equivalent version
 * @param {string} str    string to convert
 */
export function formatLineBreak(str) {
	return str.split(/\r?\n/);
}

/**
 * convert markdown string into HTML equivalent
 * @param {string} markdownStr   string to convert
 */
export function htmlifyMarkdown(markdownStr) {
	return remark()
		.use(remarkHTML)
		.processSync(markdownStr)
		.toString();
}

/**
 * parse query string
 * @param {string} str    query to parse
 * @param {string} tag    query tag to search for
 */
export function parseQS(str, tag = "") {
	if (!str) return "";

	return new URLSearchParams(str).getAll(tag);
}

/**
 * truncate strings and append ellipsis at the back
 * @param {str} str                 string to append
 * @param {num} truncateLength      length to truncate
 * @param {bool} appendEllipsis     to append ellipsis or not
 */
export function truncateStr(str, truncateLength = 300, appendEllipsis = true) {
	if (!str || typeof str !== "string") return "";

	return `${str.substring(0, truncateLength)}${
		str.length > truncateLength && appendEllipsis ? "..." : ""
	}`;
}
