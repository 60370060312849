import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import styles from "./Button.module.scss";

const propTypes = {
	element: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	text: PropTypes.string,
	/** (yellow | black) */
	colorScheme: PropTypes.string,
	onClick: PropTypes.func,
	to: PropTypes.string,
	type: PropTypes.string,
	loading: PropTypes.bool,
};
const defaultProps = {
	element: Link,
	className: "",
	text: "Button",
	colorScheme: "yellow",
	onClick: () => {},
	to: "/",
	type: null,
	loading: false,
};

function Button({ element, className, text, colorScheme, onClick, to, type, loading }) {
	const TagEl = element;

	let toPath = to;
	if (type !== null) {
		toPath = null;
	}

	return (
		<TagEl
			className={`${styles.button} ${styles[`theme--${colorScheme}`]} ${
				loading ? styles[`button--loading`] : ""
			} ${className}`}
			onClick={onClick}
			to={toPath}
			type={type}
		>
			{text}
		</TagEl>
	);
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
