import React, { useState, useEffect } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { isEmpty, isArray } from "lodash";
import { useLocation } from "@reach/router";

import useHasMounted from "../../hooks/useHasMounted";

import styles from "./Nav.module.scss";

const pages = [
	{
		id: "services",
		path: "/services",
		text: "Services",
	},
	{
		id: "work",
		path: "/work",
		text: "Work",
	},
	{
		id: "blog",
		path: "/blog",
		text: "Blog",
	},
	{
		id: "contact-us",
		path: "/contact",
		text: "Contact Us",
	},
];

const queryString = graphql`
	query {
		allFile(filter: { relativeDirectory: { eq: "services" } }) {
			edges {
				node {
					childMarkdownRemark {
						id
						frontmatter {
							service_title
						}
						fields {
							slug
						}
					}
				}
			}
		}
	}
`;

function Nav({ isDesktop }) {
	const { pathname } = useLocation();
	const hasMounted = useHasMounted();
	const [showMobileNav, setShowMobileNav] = useState(false);
	const [showServicePages, setShowServicePages] = useState(false);
	const [showServiceSubMenu, setShowServiceSubMenu] = useState(false);

	useEffect(() => {
		setShowMobileNav(false);
		setShowServicePages(false);
	}, [isDesktop]);

	useEffect(() => {
		if (!showMobileNav) {
			setShowServicePages(false);
		}
	}, [showMobileNav]);

	return (
		<StaticQuery
			query={queryString}
			render={({ allFile: { edges: servicesData } }) => {
				return (
					<>
						<nav
							className={`${styles.navWrapper__main} ${
								hasMounted && isDesktop ? "" : styles["navWrapper--isMobile"]
							}`}
						>
							<div className={styles.navWrapper__content}>
								<Link
									to="/"
									className={styles.logo}
									onClick={() => showMobileNav && setShowMobileNav(false)}
								>
									<img src={"/img/logo.svg"} alt="Secret Hideout" style={{ width: "100%" }} />
								</Link>

								<section>
									{isDesktop ? (
										pages.map(({ id, path, text }) => {
											return (
												<div key={id} className={styles.links__itemWrapper}>
													{id === "services" && !isEmpty(servicesData) && isArray(servicesData) ? (
														<>
															<div
																className={`${styles.links__itemEl} ${
																	pathname.includes("/services/") ? styles["links--isActive"] : ""
																}`}
																onMouseMove={() =>
																	!showServiceSubMenu && setShowServiceSubMenu(true)
																}
																onMouseOut={() => setShowServiceSubMenu(false)}
															>
																{text}
															</div>

															<div
																className={styles.nestedMenu__wrapper}
																style={{ display: showServiceSubMenu ? "" : "none" }}
																onMouseMove={() =>
																	!showServiceSubMenu && setShowServiceSubMenu(true)
																}
																onMouseOut={() => setShowServiceSubMenu(false)}
																onClick={() => setShowServiceSubMenu(false)}
															>
																{servicesData.map(
																	({ node: { childMarkdownRemark: serviceItem } }) => (
																		<Link
																			key={serviceItem.id}
																			to={serviceItem.fields.slug}
																			className={styles.nestedMenu__item}
																			activeClassName={styles["nestedMenu--isActive"]}
																		>
																			{serviceItem.frontmatter.service_title}
																		</Link>
																	),
																)}
															</div>
														</>
													) : (
														<Link
															to={path}
															partiallyActive
															activeClassName={styles["links--isActive"]}
															className={styles.links__itemEl}
														>
															{text}
														</Link>
													)}
												</div>
											);
										})
									) : showMobileNav ? (
										<img
											onClick={() => setShowMobileNav(false)}
											src={"/img/ui/ico-close.svg"}
											className={styles.mobileNav__btnClose}
											alt="menu close"
										/>
									) : (
										<img
											src={"/img/ui/nav-mobile.svg"}
											className={styles.mobileNav__logo}
											onClick={() => setShowMobileNav(true)}
											alt="menu"
										/>
									)}
								</section>
							</div>
						</nav>

						{showMobileNav && (
							<section className={styles.mobileNav__wrapperMain}>
								{showServicePages && !isEmpty(servicesData) && isArray(servicesData) ? (
									<>
										<div
											onClick={() => setShowServicePages(false)}
											className={`${styles.mobileNav__link} ${styles.mobileNav__btnServicesBack}`}
										>
											<img
												src={"/img/ui/ico-arrow-left.svg"}
												alt="left arrow"
												className={styles.mobileNav__btnPrev}
											/>
											Back
										</div>
										{servicesData.map(({ node: { childMarkdownRemark: serviceItem } }) => (
											<Link
												key={serviceItem.id}
												to={serviceItem.fields.slug}
												className={styles.mobileNav__link}
												onClick={() => setShowMobileNav(false)}
											>
												{serviceItem.frontmatter.service_title}
											</Link>
										))}
									</>
								) : (
									pages.map(({ id, path, text }) => {
										if (id !== "services") {
											return (
												<Link
													key={id}
													to={path}
													className={styles.mobileNav__link}
													onClick={() => setShowMobileNav(false)}
												>
													{text}
												</Link>
											);
										} else {
											return (
												<div
													key={id}
													onClick={() => setShowServicePages(true)}
													className={styles.mobileNav__link}
												>
													{text}
												</div>
											);
										}
									})
								)}
							</section>
						)}
					</>
				);
			}}
		/>
	);
}

export default Nav;
